import React, { Fragment } from "react";
import _ from "lodash";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { rhythm } from "../utils/typography";

function Pagination(props) {
    const LinkWrapper = (props) => (<div
        style={{
            display: 'flex',
            margin: rhythm(0.5),
            color: props.disabled?"#DDD":"auto"
        }}
    >
        {props.children}
    </div>)
    return (
        <Fragment>
            <div style={{ width: '100%', maxWidth: "350px", margin: `${rhythm(1)} auto`, display: 'flex', justifyContent: 'space-between' }}>
                {props.currentPage > 0 ?
                <LinkWrapper><Link to={`${props.baseUrl}/0/`}>≪</Link></LinkWrapper>
                :
                <LinkWrapper disabled>≪</LinkWrapper>
            }
                {props.totalCount > 0 && <Fragment>{
                    _.range(Math.max(props.currentPage - (props.displayRange / 2), 0),
                        Math.min(props.currentPage + props.displayRange, props.totalCount)
                    )
                        .map(pageNumber => (<LinkWrapper>
                            {pageNumber === props.currentPage ?
                                <b>{pageNumber + 1}</b>
                                :
                                <Link to={`${props.baseUrl}/${pageNumber}/`}>{pageNumber + 1}</Link>}
                        </LinkWrapper>)
                        )
                }</Fragment>}
                {props.totalCount - 1 > props.currentPage ?
                <LinkWrapper><Link to={`${props.baseUrl}/${props.totalCount-1}/`}>≫</Link></LinkWrapper>
                :
                <LinkWrapper disabled>≫</LinkWrapper>
            }
            </div>
        </Fragment>
    )
}
Pagination.defaultProps = {
    displayRange: 5
}
Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    displayRange: PropTypes.number,
    totalCount: PropTypes.number.isRequired,
    baseUrl: PropTypes.string.isRequired
}


export default Pagination;