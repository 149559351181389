import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"

import Bio from "../components/bio"
import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    let header

    const withTitleImage = (WrappedComponent) => (
      (props) =>
      <StaticQuery
      query={titleQuery}
      render={(data) => <WrappedComponent {...props} data={data}/>}/>
    )

    const title = withTitleImage(props=>
      <Image
      fixed={props.data.title.childImageSharp.fixed}
      style={{verticalAlign: 'middle'}}
      alt="43mmps"/>
    )()

    const titleMini = withTitleImage(props=>
      <Image
      fixed={props.data.titleMini.childImageSharp.fixed}
      alt="43mmps"
      style={{verticalAlign:'middle'}}
      />
    )()

    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
        <h1
        style={{marginBottom: rhythm(2)}}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to="/"
          >
            {title}
          </Link>
          <h5>The speed connect you and me.</h5>
        </h1>
      )
    } else {
      header = (
        <h5
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: 0,
            fontWeight: 200
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {titleMini}
          <span> /</span>
          </Link>
        </h5>
      )
    }
    return (
      <Wrapper>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>
            {header}
          </header>
          <main>{children}</main>
          <Bio/>
          <Footer>
            <h5 style={{marginBottom: 0}}>
            <div>Copyright© {new Date().getFullYear()} 43mmps, catLee</div>
            Built with
            {` `}
            <a target="__blank" href="https://www.gatsbyjs.org">Gatsby</a>, Visual Studio Code over code-server, and iPad Mini 2019</h5>
          </Footer>
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: ${rhythm(2)} 24px 0 24px;
  fontWeight: 200;
  fontSize: ${rhythm(0.1)}
`
const titleQuery = graphql`
  query TitleQuery {
    title: file(absolutePath: { regex: "/title.png/"}) {
      childImageSharp {
        fixed(height: 70) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    titleMini: file(absolutePath: { regex: "/title.png/"}) {
      childImageSharp {
        fixed(height: 32) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`

export default Layout
