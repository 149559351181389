import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import _ from "lodash"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { FiCalendar, FiTag } from "react-icons/fi";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, relativePages } = this.props.pageContext

    const RoundedImg = props => <img style={{border: '0px solid', borderRadius: rhythm(0.5)}} {...props}/>
    const components = {
      img: RoundedImg
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          keywords={post.frontmatter.keywords || ""}
        />
        <h1>
          {post.frontmatter.title}
        </h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(2),
            marginTop: rhythm(-1),
          }}
        >
          <FiCalendar/>{' '}{post.frontmatter.date}
        </p>
        <div style={{textAlign: 'justify'}}>
          <MDXProvider components={components}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
        </div>
        <div style={{marginTop: rhythm(2)}}>
        <h6 style={{marginBottom: 0}}><FiTag/>{' '}標籤</h6>
        {post.frontmatter.keywords && <p style={{paddingLeft: rhythm(0.5), marginTop: rhythm(0.5), paddingRight: rhythm(0.5)}}>{post.frontmatter.keywords.map(keyword=><Link to={`/keyword/${_.kebabCase(keyword)}/0/`}>{keyword}</Link>).reduce((accu, node)=>[accu, ', ', node])}</p>}
        {relativePages.length > 0 && <Fragment><h6 style={{marginBottom: 0}}><FiTag/>{' '}延伸閱讀</h6>
        {relativePages.map(({node})=>
          <div style={{marginTop: rhythm(0.5), textAlign: 'justify'}}>
            <h4 style={{marginBottom: rhythm(0.125)}}>
              <Link style={{boxShadow: `none`}}
              to={`post${node.fields.slug}`}>
                {node.frontmatter.title}
              </Link>
            </h4>
            <small>
              <p
               style={{marginBottom: rhythm(0.25)}}
            dangerouslySetInnerHTML={{__html: node.frontmatter.description || node.excerpt}}
            />
            </small>
          </div>
        )}</Fragment>}
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        description
        keywords
      }
    }
  }
`
