import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import moment from 'moment'
import { FiCalendar, FiTag } from "react-icons/fi";
import _ from "lodash"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const keywordCountTable = posts.reduce((acculuamtor, currentValue) => {
      Array.from(new Set(currentValue.node.frontmatter.keywords)).forEach(keyword => acculuamtor[keyword] = (acculuamtor[keyword] || 0) + 1)
      return acculuamtor;
    }, {});
    var postGroups = posts.map(post => {
      post.mainKeyword = post.node.frontmatter.keywords.sort((a, b) => keywordCountTable[a] < keywordCountTable[b])[0];
      return post;
    }).reduce((acculuamtor, post) => {
      if (!acculuamtor[post.mainKeyword]) {
        acculuamtor[post.mainKeyword] = [];
      }
      acculuamtor[post.mainKeyword].push(post);
      return acculuamtor;
    }, {});
    postGroups = Object.keys(postGroups).map(key => { return { keyword: key, date: new Date(Math.max.apply(null, postGroups[key].map(node => node.date))), edges: postGroups[key].map(o => o.node) } }).sort((a, b) => a.date < b.date);
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        <div style={{ margin: "20px 0 60px" }}>
          {postGroups.sort((a, b) => a.totalCount < b.totalCount).map(({ edges, keyword }, idx) =>
            <div style={{ marginBottom: rhythm(1.2) }}>
              <h6 style={{ marginBottom: rhythm(1 / 2) }}>
                <Link to={`/keyword/${_.kebabCase(keyword)}/0/`} style={{fontWeight: 400}}><FiTag />{' '}{keyword}</Link>{' '}
                <div style={{display: 'inline', color: '#FFF', background: '#AAA', padding: `0 ${rhythm(0.2)}`, border: '0px solid', borderRadius: rhythm(0.1)}}><b>{edges.length}</b></div>
              </h6>
              <div style={{ textAlign: 'justify'}}>
                {edges.slice(0, Math.min(5, edges.length)).map((node) => {
                  const title = node.frontmatter.title || node.fields.slug
                  return (<React.Fragment>
                    <div key={node.fields.slug}>
                      <h3
                        style={{
                          marginBottom: rhythm(1 / 4),
                        }}
                      >
                        <Link
                          style={{ boxShadow: `none` }}
                          to={`post${node.fields.slug}`}
                        >
                          {title}
                        </Link>
                      </h3>
                      <small><FiCalendar />{' '}{moment(node.frontmatter.date).format("MMM DD, YYYY")}</small>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </div></React.Fragment>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: { published: {eq: true} }}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            keywords
          }
        }
      }
    }
  }

`