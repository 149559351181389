import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

class Search extends React.Component {
    render() {
        const siteTitle = "";
        return (<React.Fragment>
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title={siteTitle} />
                <Bio />
            </Layout>
        </React.Fragment>)
    }
}
export default Search