import Typography from "typography"
import Moraga from "typography-theme-moraga"

Moraga.googleFonts = [
  {
    name: "Noto Sans TC",
    styles: ["400", "500", "700", "900"],
  },
  {
    name: "Noto Serif TC",
    styles: ["400", "500", "700", "900"],
  },
  {
    name: "Noto Sans JP",
    styles: ["400", "500", "700", "900"],
  },
  {
    name: "Noto Serif JP",
    styles: ["400", "500", "700", "900"],
  },
]
Moraga.headerFontFamily = ["Noto Serif TC", "Noto Serif JP"]
Moraga.bodyFontFamily = ["Noto Sans TC", "Noto Sans JP"]
//Moraga.baseFontSize = '16px'
Moraga.overrideThemeStyles = ({ rhythm }, options) => ({
  'a, a:hover': {
    color: '#0584a4'
  }
})
const typography = new Typography(Moraga)


// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
