import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { FiCalendar, FiTag } from "react-icons/fi"
import moment from 'moment'
import Pagination from "../components/pagination"
import _ from "lodash"

class BlogKeywordTemplate extends React.Component {
  render() {
    const count = this.props.data.allMdx.totalCount
    const posts = this.props.data.allMdx.edges
    const keyword = this.props.pageContext.keyword
    return (
      <Layout location={this.props.location} title={`#${keyword}`}>
        <SEO
          title={`#${keyword}`}
          description={`包含 #${keyword} 的文章`}
          keywords={[keyword]}
        />
        <h1 style={{marginBottom: rhythm(1)}}>
          {`${keyword}`}
          <h5>{`${count} 篇文章`}</h5>
        </h1>

        <Pagination
        currentPage={this.props.pageContext.page}
        totalCount={this.props.pageContext.totalCount}
        baseUrl={`/keyword/${_.kebabCase(keyword)}/`}
        />
        <div>
        {posts.map(({ node }) =>
          <div key={node.fields.slug}>
            <h3
              style={{
                marginBottom: rhythm(1 / 4),
              }}
            >
              <Link
                style={{ boxShadow: `none` }}
                to={`post${node.fields.slug}`}
              >
                {node.frontmatter.title}
              </Link>
            </h3>
            <small><FiCalendar />{' '}{moment(node.frontmatter.date).format("MMM DD, YYYY")}</small>
            <p
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt,
              }}
            />
          </div>
        )}
        <Pagination
        currentPage={this.props.pageContext.page}
        totalCount={this.props.pageContext.totalCount}
        baseUrl={`/keyword/${_.kebabCase(keyword)}/`}
        />
        </div>
      </Layout>
    )
  }
}

export default BlogKeywordTemplate

export const pageQuery = graphql`
  query($keyword: String, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: {published: {eq: true},  keywords: { in: [$keyword] } } }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`