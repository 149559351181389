/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
          style={{
            margin: `${rhythm(2)} 0 0 0`
          }}
          >
            <Image
            fixed={data.avatar.childImageSharp.fixed}
            style={{verticalAlign: 'middle', float: 'left', border:'0px solid', borderRadius:'50%', marginRight:rhythm(0.5)}}
            />
            <h2 style={{marginBottom: rhythm(0.5)}}>catLee
            <h5 style={{marginBottom: rhythm(0.5)}}><a href="mailto:leemiyinghao@gmx.com ">leemiyinghao@gmx.com</a></h5>
            </h2>
            <p style={{marginBottom: rhythm(0.5)}}>
              初音不是軟體。
            </p>
            <ul style={{marginBottom: rhythm(0.25)}}>
            <li><a target="__blank" href="https://www.plurk.com/leemiyinghao">Plurk</a> - Microbulls_hitting</li>
            <li><a target="__blank" href="https://www.instagram.com/leemiyinghao/">Instagram</a> - 使用錯誤的雲端相簿</li>
            <li><a target="__blank" href="https://github.com/leemiyinghao">Github</a> - 垃圾棄置場</li>
            </ul>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/IMG_6626.JPG/" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
`

export default Bio
